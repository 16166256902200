import * as React from 'react';
import { Link } from 'gatsby';
import { logAnalysis } from '../../api/analytic';
import { useEffect } from 'react';

const AboutPage = () => {
  useEffect(() => {
    logAnalysis('about');
  }, []);

  return (
    <main>
      <title>About Me</title>

      <h1>About Me</h1>
      <div>This is Alex Tran Blog and Is Built with Gatsby and Markdown</div>
      <Link to={'/'}>Home</Link>
    </main>
  );
};

export default AboutPage;
